<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="プライバシーポリシー" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead">見出しが入ります</h3>
          </div>
          <div>
            <div class="c-text sub-title">
              <h4>見出しが入ります</h4>
            </div>
            <div>
              <h5 class="c-lead-xs-black l-item">見出しが入ります</h5>
              <p class="l-item">
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                <a class="c-text text-link">本文にリンクが入る場合</a>
                本文が入ります本文が入ります本文が入ります
              </p>
              <p>
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
              </p>
            </div>
          </div>
        </div>

        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead">
              見出しが入ります見出しが入ります見出しが入ります見出しが入ります見出しが入ります見出しが入ります
            </h3>
          </div>
          <div>
            <div class="c-text sub-title">
              <h4>
                見出しが入ります見出しが入ります見出しが入ります見出しが入ります見出しが入ります見出しが入ります
              </h4>
            </div>
            <div class="l-area">
              <h5 class="c-lead-xs-black l-item">
                見出しが入ります見出しが入ります見出しが入ります見出しが入ります見出しが入ります
              </h5>
              <p class="l-item">
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
              </p>
            </div>
            <div>
              <h5 class="c-lead-xs-black l-item">
                見出しが入ります見出しが入ります見出しが入ります見出しが入ります見出しが入ります
              </h5>
              <p class="l-item">
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
              </p>
            </div>
          </div>
          <div>
            <div class="c-text sub-title">
              <h4>見出しが入ります見出しが入ります</h4>
            </div>
            <div>
              <p class="l-item">
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
                本文が入ります本文が入ります本文が入ります
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
export default {
  name: "privacy-policy",
  mounted() {
    // ログイン後の状態を確認するためベタ書きでログイン済みにする
    this.$store.commit("auth/set", true);
  },
};
</script>
